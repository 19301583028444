import { gql } from "@apollo/client";

export const GET_HEADER_OPTIONS = gql`
query headerOptions {
  	mainMenu : menu(id: "Main Menu", idType: NAME) {
      menuItems(first: 1000) {
        nodes {
            id
            key: id
            parentId
            title: label
            url
            cssClasses
            path
            target
            menuItem{
              image{
                sourceUrl
              }
              content
              link
            }
            childItems {
              nodes {
                id
                parentId
                title : label
                url
                path
                target
              }
            }
        }
      }
    }
  }
`;

export const SITE_SEARCH = gql`
  query siteSearch(
      $search: String!
  ){
      searchWP(where: {input: $search, nopaging: true}, first: 500) {
        nodes {
          ... on Post {
            id
            title
            uri
            excerpt
          }
          ... on Page {
            id
            title
            uri
          }
          contentTypeName
        }
      }
    }`;

export const GET_HOME_PAGE = gql`
  query homePage {
    pageBy(uri: "/") {
      databaseId
      uri
      title
      home {
        heroContent
        heroSlides {
          image {
            sourceUrl
          }
          caption
          photoCredit
        }
        threeColumnWidgetContent
        threeColumnWidget {
          image {
            sourceUrl
          }
          content
        }
        visitBackgroundImage {
          sourceUrl
        }
        visitBackgroundImageCaption
        visitContent
        visitButtons {
          button
        }
        visitTiles {
          image {
            sourceUrl
          }
          title
          link
        }
        volunteerImage {
          sourceUrl
        }
        volunteerContent
        upcomingEventsTitle
        newsImage {
          sourceUrl
        }
        newsContent
      }
    }
    eventsCategory(id: "23", idType: DATABASE_ID) {
      evt: events(first: 3) {
        edges {
          node {
            databaseId
            title
            date
            excerpt
            uri
            startDate
            endDate
            allDay
            featuredImage {
              node {
                sourceUrl
                title
                altText
              }
            }
            terms {
              nodes {
                name
                ... on NewsPreserve {
                  databaseId
                  name
                }
              }
            }
          }
        }
      }
    }
    events(first: 10, where: {}) {
      edges {
        node {
          databaseId
          title
          date
          excerpt
          uri
          startDate
          endDate
          allDay
          featuredImage {
            node {
              sourceUrl
              title
              altText
            }
          }
          terms {
            nodes {
              name
              ... on NewsPreserve {
                databaseId
                name
              }
            }
          }
        }
      }
    }
    eventsCategory(id: "23", idType: DATABASE_ID) {
      events {
        edges {
          node {
            databaseId
            title
            date
            excerpt
            uri
            startDate
            endDate
            allDay
            featuredImage {
              node {
                sourceUrl
                title
                altText
              }
            }
            terms {
              nodes {
                name
                ... on NewsPreserve {
                  databaseId
                  name
                }
              }
            }
          }
        }
      }
    }
    posts(first: 4) {
      edges {
        node {
          databaseId
          title
          uri
        }
      }
    }
  }
`;

export const GET_PAGE = gql`
  query pageBuilder($slug: String) {
    pageBy(uri: $slug) {
      databaseId
      uri
      title
      pageBuilder {
        contentRows{
          type 
          content
          headerBackgroundColor
          backgroundColor
          caption
          image {
            sourceUrl
            altText
            mediaDetails {
                sizes {
                    sourceUrl
                    height
                    width
                }
            }
          }
          gallery {
            src: sourceUrl
            alt: altText
          }
          twoColumnWidgets{
            image{
              altText
              sourceUrl
            }
            content
          }
          threeColumnWidgets{
            image{
              altText
              sourceUrl
            }
            content
          }
          fourColumnWidgets{
            image{
              altText
              sourceUrl
            }
            content
          }
          accordions{
            title
            content
          }
          eventTitle
          eventCategory {
            events {
              nodes{
                databaseId
                title
                date
                excerpt
                uri
                startDate
                endDate
                allDay
                venue {
                  city
                  state
                }
                featuredImage {
                  node {
                    sourceUrl
                    title
                    altText
                  }
                }
                terms {
                  nodes {
                    name
                    ... on EventsCategory {
                      databaseId
                      name
                    }
                  }
                }
              }
            }
          }
          featuredEvent {
            ... on Event {
              databaseId
              title
              date
              excerpt
              uri
              startDate
              endDate
              allDay
              venue {
                city
                state
              }
              featuredImage{
                node{
                  sourceUrl
                  title
                  altText
                }
              }
              terms {
                nodes {
                  name
                  ... on NewsPreserve {
                    databaseId
                    name
                  }
                }
              }
            }
          }
          featuredNews {
            ... on Post {
              databaseId
              title
              date
              excerpt
              uri
              featuredImage{
                node{
                  sourceUrl
                  altText
                  title
                  srcSet
                  sizes
                  mediaDetails{
                    sizes{
                      height
                      width
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
          threeLogos {
            logo{
              sourceUrl
            }
          }
          fourLogos {
            logo{
              sourceUrl
            }
          }
          fiveLogos {
            logo{
              sourceUrl
            }
          }
          sixLogos {
            logo{
              sourceUrl
            }
          }
          buttons{
            button
          }
          form
        }
      }
    }
  }
`;

export const GET_PRESERVE = gql`
  query preserve($slug: String){
    preservesBy(slug: $slug) {
      databaseId
      title
      featuredImage{
        node{
          sourceUrl
        }
      }
      preserveFeatures {
        nodes{
          databaseId
          name
          preserveFeature{
            icon{
              sourceUrl
            }
          }
        }
      }
      preserves {
        acreage
        alertBarContent
        alertBarLink
        description
        displayAlertBar
        galleryContent
        headerSubtitle
        imageCaption
        location
        physicalAddress
        trailMap
        visitorHours
        galleryContent
        gallery {
          src: sourceUrl
          alt: altText
        }
        galleryCaption
      }
    }
  }
`;

export const GET_NEWS_PRESERVES = gql`
  query newsPreserves($slug: [String]){
    newsPreserves(where: {slug: $slug}) {
      edges {
        node {
          posts {
            nodes {
              databaseId
              date
              excerpt
              featuredImage{
                node{
                  altText
                  srcSet
                  sizes
                  sourceUrl
                  title
                  mediaDetails{
                    width 
                    height
                  }
                }
              }
              title
              uri
            }
          }
        }
      }
    }
  }
`;

export const GET_PRESERVE_CATEGORIES = gql`
  query preserveCategories{
    preserveCategories{
      nodes{
        databaseId,
        uri,
        name,
        slug
      }
    }
  }
`;

export const GET_PRESERVES_LANDING_PAGE = gql`
  query preservesLandingPage {
    allPreserves(first: 1000){
      nodes{
        databaseId
        title
        uri 
        featuredImage {
          node {
            sourceUrl
            title
            sizes
            altText
            mediaDetails {
              width
              height
            }
            srcSet
          }
        }
        preserves{
          acreage
          location
        }
      }
    }
    siteOptions{
      preservesLandingPage{
        headerContent
        leftContent
        rightImage{
          id
          altText
          srcSet
          title
          sourceUrl
          sizes
          mediaDetails{
            width
            height
            sizes {
              file
              fileSize
              height
              mimeType
              name
              sourceUrl
              width
            }
          }
        }
        rightImageCaption
        cta: callToAction {
          ... on CallToAction {
            callToAction {
              backgroundImage {
                sourceUrl
              }
              content
              buttons {
                button
              }
            }
          }
        }
        widgets{
          content
          image{
            id
            altText
            srcSet
            title
            sourceUrl
            sizes
            mediaDetails{
              width
              height
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
      }
    } 
  }
`;

export const GET_PRESERVE_BY_CATEGORY = gql`
  query preserveByCategory($slug: [String]) {
    preserveCategories(where: {slug: $slug}) {
      nodes {
        databaseId
        uri
        name
        preserves {
          nodes{
            databaseId
            title
            uri 
            featuredImage {
              node {
                sourceUrl
                title
                sizes
                altText
                mediaDetails {
                  width
                  height
                }
                srcSet
              }
            }
            preserves{
              acreage
              location
            }
          }
        }
      }
    }
  }
`;

export const GET_NEWS_DATA = gql`
  query newsPost($slug: String){
    postBy(slug: $slug) {
      databaseId
      title
      date
      content
      featuredImage {
        node {
          sourceUrl
          title
          sizes
          altText
          mediaDetails {
            width
            height
          }
          srcSet
        }
      }
    }
  }
`;

export const GET_NEWS_POSTS = gql`
    query newsPosts(
        $first: Int
        $last: Int
        $after: String
        $before: String
    ) {
    posts(first: $first, last: $last, after: $after, before: $before) {
    	pageInfo{
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
      }
      edges{
        cursor
        node{
          id
          databaseId
          title
          uri
          date
          excerpt
          categories{
            edges{
              node{
              	uri
                name
              }
            }
          }
          featuredImage {
            node {
              id
              altText
              srcSet
              title
              sourceUrl
              sizes
              mediaDetails {
                width
                height
                sizes(include: _1060_680_IMAGE_SIZE) {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
}`;

export const GET_CATEGORY_POSTS = gql`
    query categoryPosts(
        $first: Int
        $last: Int
        $after: String
        $before: String,
        $category: String,
    ) {
    categories(where: {slug: [$category]}) {
        edges {
            node {
                id
                name
                posts(first: $first, last: $last, after: $after, before: $before) {
                    pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                    }
                    edges {
                    cursor
                    node {
                        id
                        databaseId
                        title
                        uri
                        date
                        excerpt
                        categories {
                          edges {
                            node {
                              id
                              uri
                              name
                            }
                          }
                        }
                        featuredImage {
                          node {
                            id
                            altText
                            srcSet
                            title
                            sourceUrl
                            sizes
                            mediaDetails {
                              width
                              height
                              sizes(include: _1060_680_IMAGE_SIZE) {
                                sourceUrl
                              }
                            }
                          }
                        }
                    }
                    }
                }
            }
        }
    }
  }`;

export const GET_CATEGORIES = gql `
  query getCategories {
    categories(first: 1000, where: {hideEmpty: true}) {
      nodes {
        name
        uri
      }
    }
  }
`;

export const GET_STAFF_MENU = gql`
  query staffMenu {
    menu(id: "Staff Menu", idType: NAME) {
      menuItems {
        edges {
          node {
            id
            url
            label
            path
          }
        }
      }
    }
  }
`;

export const GET_STAFF_PAGE = gql`
  query staffPageData {
    pageBy(pageId: 14) {
      databaseId
      uri
      title
      staffandLeadership{
        headerContent
        image{
            id
            altText
            srcSet
            title
            sourceUrl
            sizes
            mediaDetails{
              width
              height
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
        }
        imageCaption
        staffTitle
        staffMembers{
          ... on Staff{
            featuredImage{
              node{
                id
                altText
                srcSet
                title
                sourceUrl
                sizes
                mediaDetails{
                  width
                  height
                  sizes {
                    file
                    fileSize
                    height
                    mimeType
                    name
                    sourceUrl
                    width
                  }
                }
              }
            }
            uri
            title
            staff{
              title
            }
          }
        }
        cta: callToAction {
              ... on CallToAction {
                callToAction {
                  backgroundImage {
                    sourceUrl
                  }
                  content
                  buttons {
                    button
                  }
                }
              }
            }
      }
    }
  }
`;

export const GET_STAFF_ALTERNATE_DATA = gql`
  query staffPageData ($slug: String){
    pageBy(uri: $slug) {
      databaseId
      title
      staffAlternate {
        headerContent
        image{
          id
          altText
          srcSet
          title
          sourceUrl
          sizes
          mediaDetails{
            width
            height
            sizes {
              file
              fileSize
              height
              mimeType
              name
              sourceUrl
              width
            }
          }
        }
        imageCaption
        title
        singleColumnContent
        twoColumnContent{
          content
        }
        cta: callToAction {
            ... on CallToAction {
              callToAction {
                backgroundImage {
                  sourceUrl
                }
                content
                buttons {
                  button
                }
              }
            }
          }
      }
    }
  }
`;

export const GET_STAFF_DATA = gql`
  query staff($slug: String){
    staffBy(slug: $slug) {
      databaseId
      featuredImage {
        node {
          sourceUrl
          title
          sizes
          altText
          mediaDetails {
            width
            height
          }
          srcSet
        }
      }
      title
      staff{
        biography
        title
      }
    }
  }
`;

export const GET_FOOTER_OPTIONS = gql`
  query footerOptions {
    siteOptions {
      siteOptions {
        address
        mobileAddress
        facebookLink
        instagramLink
        linkedinLink
        tiktokLink
        disclaimer
        logos {
          logo {
            sourceUrl
          }
        }
        form
        copyrightContent
        headerContent
        cta: newsCallToAction {
          ... on CallToAction {
            callToAction {
              backgroundImage {
                sourceUrl
              }
              content
              buttons {
                button
              }
            }
          }
        }
        errorContent
        formUrl
      }
    }
    menu(id: "Footer Menu", idType: NAME) {
      menuItems {
        edges {
          node {
            id
            url
            label
            path
          }
        }
      }
    }
  }
`;